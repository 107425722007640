<template>
  <div class="formdata" style="padding: 20px">
    <div class="flex">
      <div class="btn" @click="lastDate()">
        <i class="el-icon-caret-left" />
      </div>

      <el-button type="text" @click="onweek"> 本周 </el-button>

      <div class="btn" @click="nextDate()">
        <i class="el-icon-caret-right" />
      </div>
      <div>{{ startDate }}-{{ endDate }}</div>
    </div>
    <div class="flex" style="margin-bottom: 10px">
      <div style="padding-right: 20px">{{ doctorDetail.teamName }}</div>
      <div style="padding-right: 20px">{{ doctorDetail.deptName }}</div>
      <div style="padding-right: 20px">{{ doctorDetail.doctorName }}</div>
      <div>{{ doctorDetail.mobile }}</div>
    </div>
    <div>
      <div
        v-for="item in list"
        :key="item.scheduleDate"
        class="flex mb-20 schedule-box"
      >
        <div class="w25">
          <div>{{ weekMap[item.week] }}</div>
          <div>({{ item.scheduleDate }})</div>
        </div>
        <div v-for="data in item.timeTypeList" :key="data.timeType" class="w25">
          <div>
            {{ dayMap[data.timeType] }}
            <a
              @click="onEdit(data, item.scheduleDate)"
              class="dodgerblue"
              v-if="data.edit"
              >编辑</a
            >
          </div>
          <div
            v-for="schedule in data.scheduleDataList"
            :key="item.scheduleDate + schedule.startTime"
            style="margin-bottom: 5px; position: relative"
          >
            <span
              v-if="!schedule.scheduleStatus"
              style="
                border-radius: 50%;
                border: 1px solid red;
                color: red;
                font-size: 10px;
                position: absolute;
                left: -15px;
              "
              >停</span
            >
            {{ schedule.startTime }}-{{ schedule.endTime }}
            <span>已预约{{ schedule.orderNum }}人</span>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="编辑"
      :visible.sync="serviceDialogVisible"
      width="600"
      style="padding-top: 10px"
    >
      <h4 style="margin-bottom: 10px">
        {{ rosterDate }}{{ timeTypeDic[timeType] }}
      </h4>
      <template>
        <el-checkbox-group
          v-model="checkList"
          style="
            display: flex;
            flex-direction: column;
            max-height: 400px;
            overflow: auto;
          "
        >
          <el-checkbox
            v-for="(item, index) in currentTimeList"
            :key="index"
            :label="item.scheduleStartTime"
            style="margin-bottom: 10px"
            :disabled="!item.scheduleStatus || !item.edit"
          >
            <div>
              <span style="margin-right: 10px">时间段{{ index + 1 }}</span>
              <span style="margin-right: 10px"
                >开始时间{{ item.scheduleStartTime }}-{{
                  item.scheduleEndTime
                }}</span
              >
              <span>号源数量</span>
              <el-input
                size="mini"
                style="width: 100px"
                v-model="item.sourceNum"
                :disabled="!item.edit"
              />
              <el-button
                v-if="
                  item.scheduleStatus &&
                  item.edit &&
                  checkList.includes(item.scheduleStartTime)
                "
                size="mini"
                type="danger"
                @click="stopSchedule(item, index)"
                >停诊</el-button
              >
              <el-button
                v-if="!item.scheduleStatus && item.edit"
                size="mini"
                @click="startSchedule(item, index)"
                >开启</el-button
              >
              <span v-if="item.orderNum">已预约{{ item.orderNum }}人</span>
            </div>
          </el-checkbox>
        </el-checkbox-group>
      </template>

      <span slot="footer" class="dialog-footer">
        <el-button @click="serviceDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveData">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="停诊" :visible.sync="stopDialogVisible">
      <h4>{{ rosterDate }}{{ timeTypeDic[timeType] }}</h4>
      <template>
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="110px"
          @submit.native.prevent
        >
          <div class="radio-margin">
            <el-radio-group v-model="form.reason">
              <div class="radio-margin">
                <el-radio label="1"> 调休 </el-radio>
              </div>
              <div class="radio-margin">
                <el-radio label="2"> 其他 </el-radio>
              </div>
            </el-radio-group>
          </div>

          <el-form-item
            v-if="form.reason === '2'"
            label="停诊原因:"
            prop="closeScheduleCause"
          >
            <el-input
              v-model="form.closeScheduleCause"
              placeholder="请输入停诊原因"
              style="width: 80%"
              maxlength="100"
            />
          </el-form-item>
        </el-form>
      </template>

      <span slot="footer" class="dialog-footer">
        <el-button @click="stopDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveStopData">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import moment from 'moment';
import { timeTypeDic } from '@/utils/component/publicList.js';
const MORNING_TIME_LIST = [
  { scheduleStartTime: '00:00', scheduleEndTime: '00:30' },
  { scheduleStartTime: '00:30', scheduleEndTime: '01:00' },
  { scheduleStartTime: '01:00', scheduleEndTime: '01:30' },
  { scheduleStartTime: '01:30', scheduleEndTime: '02:00' },
  { scheduleStartTime: '02:00', scheduleEndTime: '02:30' },
  { scheduleStartTime: '02:30', scheduleEndTime: '03:00' },
  { scheduleStartTime: '03:00', scheduleEndTime: '03:30' },
  { scheduleStartTime: '03:30', scheduleEndTime: '04:00' },
  { scheduleStartTime: '04:00', scheduleEndTime: '04:30' },
  { scheduleStartTime: '04:30', scheduleEndTime: '05:00' },
  { scheduleStartTime: '05:00', scheduleEndTime: '05:30' },
  { scheduleStartTime: '05:30', scheduleEndTime: '06:00' },
  { scheduleStartTime: '06:00', scheduleEndTime: '06:30' },
  { scheduleStartTime: '06:30', scheduleEndTime: '07:00' },
  { scheduleStartTime: '07:00', scheduleEndTime: '07:30' },
  { scheduleStartTime: '07:30', scheduleEndTime: '08:00' },
  { scheduleStartTime: '08:00', scheduleEndTime: '08:30' },
  { scheduleStartTime: '08:30', scheduleEndTime: '09:00' },
  { scheduleStartTime: '09:00', scheduleEndTime: '09:30' },
  { scheduleStartTime: '09:30', scheduleEndTime: '10:00' },
  { scheduleStartTime: '10:00', scheduleEndTime: '10:30' },
  { scheduleStartTime: '10:30', scheduleEndTime: '11:00' },
  { scheduleStartTime: '11:00', scheduleEndTime: '11:30' },
  { scheduleStartTime: '11:30', scheduleEndTime: '12:00' },
];
const AFTERNOON_TIME_LIST = [
  { scheduleStartTime: '12:00', scheduleEndTime: '12:30' },
  { scheduleStartTime: '12:30', scheduleEndTime: '13:00' },
  { scheduleStartTime: '13:00', scheduleEndTime: '13:30' },
  { scheduleStartTime: '13:30', scheduleEndTime: '14:00' },
  { scheduleStartTime: '14:00', scheduleEndTime: '14:30' },
  { scheduleStartTime: '14:30', scheduleEndTime: '15:00' },
  { scheduleStartTime: '15:00', scheduleEndTime: '15:30' },
  { scheduleStartTime: '15:30', scheduleEndTime: '16:00' },
  { scheduleStartTime: '16:00', scheduleEndTime: '16:30' },
  { scheduleStartTime: '16:30', scheduleEndTime: '17:00' },
  { scheduleStartTime: '17:00', scheduleEndTime: '17:30' },
  { scheduleStartTime: '17:30', scheduleEndTime: '18:00' },
  { scheduleStartTime: '18:00', scheduleEndTime: '18:30' },
  { scheduleStartTime: '18:30', scheduleEndTime: '19:00' },
];
const NIGHT_TIME_LIST = [
  { scheduleStartTime: '19:00', scheduleEndTime: '19:30' },
  { scheduleStartTime: '19:30', scheduleEndTime: '20:00' },
  { scheduleStartTime: '20:00', scheduleEndTime: '20:30' },
  { scheduleStartTime: '20:30', scheduleEndTime: '21:00' },
  { scheduleStartTime: '21:00', scheduleEndTime: '21:30' },
  { scheduleStartTime: '21:30', scheduleEndTime: '22:00' },
  { scheduleStartTime: '22:00', scheduleEndTime: '22:30' },
  { scheduleStartTime: '22:30', scheduleEndTime: '23:00' },
  { scheduleStartTime: '23:00', scheduleEndTime: '23:30' },
  { scheduleStartTime: '23:30', scheduleEndTime: '23:59' },
];

export default {
  name: 'Roster',
  data() {
    return {
      serviceDialogVisible: false,
      stopDialogVisible: false,
      timeTypeDic: timeTypeDic, // 时间类型字典
      startDate: '',
      endDate: '',
      date: new Date(),
      keyMap: {},
      tableData: [],
      doctorDetail: JSON.parse(this.$route.query.detail),
      titleType: '', // 弹框是排班还是停诊
      rosterDate: '', // 排班日期
      timeType: '', // 时间段
      form: {
        closeScheduleCause: '',
        reason: '',
      },
      rules: {
        closeScheduleCause: [
          { required: true, message: '请输入停诊原因', trigger: 'blur' },
        ],
      },
      cols: [
        { timeType: 'MORNING', label: '上午' },
        { timeType: 'AFTERNOON', label: '下午' },
        { timeType: 'NIGHT', label: '晚上' },
      ],
      list: [],
      weekMap: {
        monday: '星期一',
        tuesday: '星期二',
        wednesday: '星期三',
        thursday: '星期四',
        friday: '星期五',
        saturday: '星期六',
        sunday: '星期日',
      },
      dayMap: {
        MORNING: '上午',
        AFTERNOON: '下午',
        NIGHT: '晚上',
      },
      scheduleData: [],
      checkList: [],
      currentTimeList: [],
      currentScheduleStartTime: '',
      currentScheduleEndTime: '',
      curIndex: null,
    };
  },
  watch: {
    'form.reason': function (value) {
      console.log(value, 'value');
      if (value === '1') {
        this.form.closeScheduleCause = '调休';
      } else {
        this.form.closeScheduleCause = '';
      }
    },
  },
  mounted() {
    this.onweek();
  },
  methods: {
    onEdit(val, date) {
      console.log(val, date);
      this.rosterDate = date;
      this.timeType = val.timeType;
      this.checkList = [];
      let tempList = [];
      if (this.timeType === 'MORNING') {
        tempList = MORNING_TIME_LIST;
      } else if (this.timeType === 'AFTERNOON') {
        tempList = AFTERNOON_TIME_LIST;
      } else if (this.timeType === 'NIGHT') {
        tempList = NIGHT_TIME_LIST;
      }
      console.log(new Date(), new Date().getTime());
      const nowTime = new Date().getTime();
      for (let i = 0; i < tempList.length; i++) {
        const temp = tempList[i];
        temp.scheduleStatus = true;
        temp.sourceNum = null;
        temp.orderNum = null;
        temp.edit = true;
        let tempTime = `${date} ${temp.scheduleStartTime}`;
        console.log('tempTime', tempTime);
        tempTime = new Date(tempTime).getTime();
        if (tempTime <= nowTime) {
          console.log('tempTime<=nowTime:', tempTime);
          temp.edit = false;
        }
        for (let j = 0; j < val.scheduleDataList.length; j++) {
          let scheduleData = val.scheduleDataList[j];
          if (temp.scheduleStartTime === scheduleData.startTime) {
            temp.sourceNum = scheduleData.sourceTotal;
            temp.scheduleStatus = scheduleData.scheduleStatus;
            temp.orderNum = scheduleData.orderNum;
            if (temp.edit) {
              temp.edit = scheduleData.edit;
              this.checkList.push(scheduleData.startTime);
            }
            break;
          }
        }
      }
      console.log('tempList:', tempList);
      this.currentTimeList = tempList;
      this.serviceDialogVisible = true;
    },
    stopSchedule(val, index) {
      console.log('stopSchedule:', val);
      const { scheduleStartTime, scheduleEndTime } = val;
      this.currentScheduleStartTime = scheduleStartTime;
      this.currentScheduleEndTime = scheduleEndTime;
      this.curIndex = index;
      this.stopDialogVisible = true;
    },
    startSchedule(val, index) {
      const { rosterDate, timeType } = this;
      const { scheduleStartTime, scheduleEndTime } = val;
      const params = {
        doctorId: this.$route.query.doctorId,
        scheduleDate: rosterDate,
        timeType: timeType,
        scheduleStartTime: scheduleStartTime + ':00',
        scheduleEndTime: scheduleEndTime + ':00',
      };
      console.log('startSchedule:', val);
      this.$api.startSchedule(params).then((res) => {
        if (res.code === 0) {
          this.$message.success('开诊成功');
          this.currentTimeList[index].scheduleStatus = true;
          this.getRosterDetail();
        }
      });
    },
    // 当前时间上周
    lastDate() {
      var date1 = new Date(this.date.getTime() - 7 * 24 * 3600 * 1000);
      this.DateWeek(date1);
      this.date = date1;
      this.getRosterDetail();
    },
    onweek() {
      this.date = new Date();
      this.DateWeek(this.date);
      this.getRosterDetail();
    },
    // 当前时间的下周
    nextDate() {
      var date1 = new Date(this.date.getTime() + 7 * 24 * 3600 * 1000);
      this.DateWeek(date1);
      this.date = date1;
      this.getRosterDetail();
    },
    // 获取日期
    getDate(date) {
      var weekOfday = moment(date).format('E'); // 计算今天是这周第几天
      var last_monday = moment(date)
        .subtract(weekOfday - 1, 'days')
        .format('YYYY-MM-DD'); // 周一日期
      const lastTuesday = moment(date)
        .subtract(weekOfday - 2, 'days')
        .format('YYYY-MM-DD'); // 周二日期
      const lastWednesday = moment(date)
        .subtract(weekOfday - 3, 'days')
        .format('YYYY-MM-DD'); // 周三日期
      const lastThursday = moment(date)
        .subtract(weekOfday - 4, 'days')
        .format('YYYY-MM-DD'); // 周四日期
      const lastFriday = moment(date)
        .subtract(weekOfday - 5, 'days')
        .format('YYYY-MM-DD'); // 周五日期
      var lastSaturday = moment(date)
        .subtract(weekOfday - 6, 'days')
        .format('YYYY-MM-DD'); // 周六日期

      var last_sunday = moment(date)
        .add(7 - weekOfday, 'days')
        .format('YYYY-MM-DD'); // 周日日期
      var list = [
        last_monday,
        lastTuesday,
        lastWednesday,
        lastThursday,
        lastFriday,
        lastSaturday,
        last_sunday,
      ];
      return list;
    },
    // 获取当前时间一周的日期以及周几
    DateWeek(date) {
      var weekOfday = moment(date).format('E'); // 计算今天是这周第几天
      var last_monday = moment(date)
        .subtract(weekOfday - 1, 'days')
        .format('YYYY-MM-DD'); // 周一日期
      const lastTuesday = moment(date)
        .subtract(weekOfday - 2, 'days')
        .format('YYYY-MM-DD'); // 周二日期
      const lastWednesday = moment(date)
        .subtract(weekOfday - 3, 'days')
        .format('YYYY-MM-DD'); // 周三日期
      const lastThursday = moment(date)
        .subtract(weekOfday - 4, 'days')
        .format('YYYY-MM-DD'); // 周四日期
      const lastFriday = moment(date)
        .subtract(weekOfday - 5, 'days')
        .format('YYYY-MM-DD'); // 周五日期
      var lastSaturday = moment(date)
        .subtract(weekOfday - 6, 'days')
        .format('YYYY-MM-DD'); // 周六日期

      var last_sunday = moment(date)
        .add(7 - weekOfday, 'days')
        .format('YYYY-MM-DD'); // 周日日期
      console.log(last_monday, last_sunday);
      this.keyMap = {
        星期一: last_monday,
        星期二: lastTuesday,
        星期三: lastWednesday,
        星期四: lastThursday,
        星期五: lastFriday,
        星期六: lastSaturday,
        星期日: last_sunday,
      };
      this.startDate = this.transferDate(last_monday);
      this.endDate = this.transferDate(last_sunday);
    },
    // 将日期转换为年月日
    transferDate(date1) {
      var date = new Date(date1);
      var mondayDateStr =
        date.getFullYear() +
        '年' +
        (date.getMonth() + 1) +
        '月' +
        date.getDate() +
        '日';
      return mondayDateStr;
    },
    getRosterDetail() {
      const { doctorDetail } = this;
      const param = {
        doctorId: this.$route.query.doctorId,
        startDate: this.startDate
          .replace('年', '-')
          .replace('月', '-')
          .replace('日', ''),
        endDate: this.endDate
          .replace('年', '-')
          .replace('月', '-')
          .replace('日', ''),
      };
      this.$api.doctorScheduleDetail(param).then((res) => {
        console.log(res, 'res');
        const { data } = res;
        const timeTypeList = ['MORNING', 'AFTERNOON', 'NIGHT'];
        data.forEach((ele) => {
          var a = ele.timeTypeList;
          var list3 = timeTypeList.map((v) => {
            var b = a.filter((v1) => {
              return v === v1.timeType;
            });
            if (b.length > 0) {
              return b[0];
            } else {
              return {
                scheduleDataList: [],
                edit: false,
                timeType: v,
              };
            }
          });
          ele.timeTypeList = list3;
        });
        const currentWeekDate = this.getDate(this.date);
        const list = currentWeekDate.map((v) => {
          var b = data.filter((v1) => {
            return v1.scheduleDate === v;
          });
          if (b.length > 0) {
            return b[0];
          } else {
            return {
              scheduleDate: v,
              detailList: [
                {
                  orderNumber: 0,
                  remainNumber: 0,
                  scheduleStatus: 'roster',
                  timeType: 'MORNING',
                },
                {
                  orderNumber: 0,
                  remainNumber: 0,
                  scheduleStatus: 'roster',
                  timeType: 'AFTERNOON',
                },
                {
                  orderNumber: 0,
                  remainNumber: 0,
                  scheduleStatus: 'roster',
                  timeType: 'NIGHT',
                },
              ],
            };
          }
        });
        console.log(list);
        this.list = list || [];
        this.tableData = [
          {
            teamName: doctorDetail.teamName,
            doctorName: doctorDetail.doctorName,
            mobile: doctorDetail.mobile,
            deptName: doctorDetail.deptName,
            monday: list[0],
            tuesday: list[1],
            wednesday: list[2],
            thursday: list[3],
            friday: list[4],
            saturday: list[5],
            sunday: list[6],
          },
        ];
      });
    },
    // 排班和停诊公共操作
    publicMethod(date, type) {
      this.rosterDate = date;
      this.serviceDialogVisible = true;
      this.timeType = type;
    },
    // 排班弹框
    operateRoster(date, timeType) {
      this.publicMethod(date.scheduleDate, timeType);
      this.titleType = '排班';
    },
    // 停诊弹框
    operateClose(date, timeType) {
      this.publicMethod(date.scheduleDate, timeType);
      this.titleType = '停诊';
    },
    saveData() {
      const { rosterDate, timeType, form } = this;
      console.log(this.checkList, this.currentTimeList);
      let timeList = [];
      for (let i = 0; i < this.checkList.length; i++) {
        for (let j = 0; j < this.currentTimeList.length; j++) {
          const {
            scheduleStartTime,
            scheduleEndTime,
            sourceNum,
            scheduleStatus,
          } = this.currentTimeList[j];
          if (this.checkList[i] === scheduleStartTime && scheduleStatus) {
            timeList.push({
              scheduleStartTime: scheduleStartTime + ':00',
              scheduleEndTime: scheduleEndTime + ':00',
              sourceNum: sourceNum,
            });
            break;
          }
        }
      }
      console.log('timeList:', timeList);
      if (timeList.length < 1) {
        this.$message.success('请选择一个时间段');
        return;
      }
      var param = {
        doctorId: this.$route.query.doctorId,
        scheduleDate: rosterDate,
        timeType: timeType,
        timeList: timeList,
      };
      this.$api.openSchedule(param).then((res) => {
        if (res.code === 0) {
          this.serviceDialogVisible = false;
          this.$message.success('排班成功');
          this.getRosterDetail();
        }
      });
    },
    saveStopData() {
      const { rosterDate, timeType, form } = this;
      const params = {
        doctorId: this.$route.query.doctorId,
        scheduleDate: rosterDate,
        timeType: timeType,
        scheduleStartTime: this.currentScheduleStartTime + ':00',
        scheduleEndTime: this.currentScheduleEndTime + ':00',
        closeScheduleCause: form.closeScheduleCause,
      };
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$api.closeSchedule(params).then((res) => {
            if (res.code === 0) {
              this.stopDialogVisible = false;
              this.$message.success('停诊成功');
              this.currentTimeList[this.curIndex].scheduleStatus = false;
              this.getRosterDetail();
            }
          });
        } else {
          return false;
        }
      });
    },
    // 是否显示
    isShowRoster(v1, v2) {
      var a = v1.find((item) => {
        if (item.timeType === v2) {
          return item.scheduleStatus === 'roster';
        }
      });
      return a;
    },
    isShowClose(v1, v2) {
      var a = v1.find((item) => {
        if (item.timeType === v2) {
          return item.scheduleStatus === 'BEGIN';
        }
      });
      return a;
    },
    closed(v1, v2) {
      var a = v1.find((item) => {
        if (item.timeType === v2) {
          return item.scheduleStatus === 'CLOSE';
        }
      });
      return a;
    },
    getAppointNum(v1, v2) {
      console.log(v1, v2, 'zhongguo ');
      var num = 0;
      v1.forEach((item) => {
        if (item.timeType === v2) {
          num = item.orderNumber;
        }
      });

      console.log(num);
      return num;
    },
    // 日期是否过去
    pastDate(date) {
      var date1 = date.replace(/-/g, '/');
      var currentDate = new Date();
      var receiveDate = new Date(Date.parse(date1));
      var isPast = false;
      if (receiveDate < currentDate) {
        isPast = true;
      }
      return isPast;
    },
    pastDateClose(date, type) {
      console.log(date, type);
      let date1 = '';
      switch (type) {
        case 'MORNING':
          date1 = date + ' ' + '12:30:00';
          break;
        case 'AFTERNOON':
          date1 = date + ' ' + '18:30:00';
          break;
        case 'NIGHT':
          date1 = date + ' ' + '21:30:00';
          break;
        default:
          break;
      }
      var currentDate = new Date();
      var receiveDate = new Date(date1);
      var isPast = false;
      if (receiveDate < currentDate) {
        isPast = true;
      }
      return isPast;
    },
    // 排班显示与否是否为当天
    isCurrentDate(date) {
      var currentDate = moment(new Date()).format('YYYY-MM-DD');
      var isCurrent = false;
      if (date === currentDate) {
        isCurrent = true;
      }
      return isCurrent;
    },
    // 是否在14天内
    inDateScope(date) {
      var currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 14);
      var date1 = moment(currentDate).format('YYYY-MM-DD');
      var a = true;
      console.log(date, date1, 'riqi');
      if (date > date1) {
        a = false;
      }
      return a;
    },
  },
};
</script>
<style scoped>
.flex {
  display: flex;
  align-items: center;
}

.fontColor {
  color: red;
  font-weight: 700;
}
.dodgerblue {
  color: dodgerblue;
}
.w25 {
  width: 25%;
}
.schedule-box {
  min-height: 100px;
  align-items: baseline;
}
.mb-20 {
  margin-bottom: 20px;
}
.radio-margin {
  margin-left: 20px;
  margin-bottom: 10px;
}
</style>
